import React from 'react';

import I18n from '~/src/common/services/I18n';
import { getDeliveryDateWithTimeslotInterval } from '~/src/common/utils/date';
import { isTimeslotValid } from '~/src/common/utils/delivery';

import * as S from './layout';

export interface Props {
  deliveryAddress?: { streetNumber: string; street: string };
  deliveryZoneName?: string;
  timeSlot?: { from: number; to: number; orderUntil: number } | null;
  isPickupMode?: boolean;
  onDeliveryAddressClick: () => void;
  onDeliverySlotClick: () => void;
}

const DeliveryItems = ({
  deliveryAddress,
  deliveryZoneName,
  timeSlot,
  isPickupMode = false,
  onDeliveryAddressClick,
  onDeliverySlotClick,
}: Props) => {
  const hasAddress = deliveryAddress || deliveryZoneName;
  const formattedDeliveryAddress = deliveryAddress
    ? `${deliveryAddress.streetNumber} ${deliveryAddress.street}`
    : '';
  const addressLabel = isPickupMode
    ? I18n.translate({
        value: 'navigation.collect',
        name: deliveryZoneName ?? '',
      })
    : formattedDeliveryAddress;

  const hasTimeslot = timeSlot && isTimeslotValid(timeSlot);
  const slotLabel = hasTimeslot
    ? getDeliveryDateWithTimeslotInterval(timeSlot, false, false)
    : I18n.t('navigation.choose-slot');

  return (
    <S.DeliveryContainer>
      <S.DeliveryButtonItem
        onClick={onDeliveryAddressClick}
        variant="secondary"
        icon="marker-normal"
        iconSize={16}
        $isToComplete={!hasAddress}>
        {hasAddress ? addressLabel : I18n.t('navigation.no-address')}
      </S.DeliveryButtonItem>
      {hasAddress ? (
        <S.DeliveryButtonItem
          label={I18n.t('navigation.choose-slot-aria-label')}
          onClick={onDeliverySlotClick}
          variant="secondary"
          icon="calendar"
          iconSize={16}
          $isToComplete={!hasTimeslot}>
          {slotLabel}
        </S.DeliveryButtonItem>
      ) : null}
    </S.DeliveryContainer>
  );
};

export default DeliveryItems;
